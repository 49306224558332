//
// reboot.scss
//

body {
  &.loading {
    visibility: hidden;
  }
}

button, a {
  outline: none !important;
}

// Forms

label {
  font-weight: $font-weight-semibold;
}

// Address
address.address-lg {
  line-height: 24px;
}

b, strong {
  font-weight: $font-weight-bold;
}

// CUSTOM MINER
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.zalacznik {
  background-color: rgba(128, 128, 128, 0.05);

  .file-name, .file-download, .file-delete {
    transition: color 250ms;
  }

  a, span {
    color: var(--bs-card-color);
  }

  .file-name:hover, .file-download:hover {
    color: black;
  }

  .file-delete:hover {
    color: red;
  }
}

.zalaczniki {
  padding: 0;
}

.badge-priority {
  font-size: 0.8rem;
  vertical-align: top;
  height: 21px;
  width: 100%;
}

.finanse {
  display: block;
}

.page-title {
  min-height: 60px;
}

.edit-btn {
  font-size: 1.5rem;
}

.save-btn {
  box-shadow: 0 3px 6px 0 rgba(10, 207, 151, 0.5);
}

.status-badge {
  padding: 0 0.4em;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-bg {
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #202020;
  opacity: 0.55;
  overflow: hidden; /* disable scrolling*/
  z-index: 2; /* higher than all other items, but lower than
    the confirm box*/
}

.avatar-file {
  height: 3rem;
  width: 3rem;
}

.color-piomartel {
  color: #0fb7ef;
}

.color-polana {
  color: #f4d00a;
}

.color-novo {
  color: #20d70a;
}

.color-telkomtech {
  color: #d8731e;
}
