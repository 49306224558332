//
// dropzone.scss
//

.dropzone {
    border: 2px dashed $input-border-color;
    background: $input-bg;
    border-radius: 6px;
    cursor: pointer;
    //min-height: 150px;
    //padding: 20px;

    .dz-message {
        text-align: center;
        padding: 3rem 0 3rem 0;
        outline: none;
    }

    .dz-message-small {
        text-align: center;
        padding: 1rem 0 1rem 0;
        outline: none;
    }

    &.dz-started {
        .dz-message {
            display: none;
        }
    }
}
