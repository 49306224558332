@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@100;200;300;400;500;600;700;800;900&display=swap');

.list-group {
  height: 100%;
}

.list-group-item {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  cursor: grab;
}

.list-group-item-dragging {
  cursor: grabbing !important;
}

.icon-hover-red :hover {
  color: red !important;
  cursor: pointer;
}

.icon-hover-black {
  transition: color 200ms;

  &:hover {
    color: black;
    cursor: pointer;
  }
}

.dropdown-menu {
  max-height: 300px;
  overflow: auto;
}

#filterForm .react-select :last-child {
  border: none;
}

.filter_form_select {
  border: 1px solid #dee2e6;
  color: #838c96;
}

.table-footer-cell-div {
  height: 100%;
  width: 100%;
  display: grid;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

//#search .react-select__control {
//  background: none !important;
//}

#project_history_timeline {
  padding: 0 !important;
}

.hover-rows tbody tr {
  transition: background-color 150ms;
}

.hover-rows tbody tr:hover {
  background-color: rgba(184, 185, 187, 0.1);
}

.page-title-box .page-title-right {
  margin-top: 13px !important;
}

.page-title {
  line-height: 60px !important;
}

.breadcrumb {
  margin-bottom: 0 !important;
}

.monthButton:focus {
  outline: none;
  box-shadow: none;
}

.rbc-event {
  background-color: #55b4ea !important;
}

.donut-chart-text-center {
  position: absolute;
  transform: translate(-50%, -20%);
  top: 40%;
  left: 50%;
  text-align: center;
  //z-index: 0;
}

.rounded-4 {
  border-radius: 20px;
}

.horizontal-scrollable {
  overflow-x: auto;
  white-space: nowrap;
}

.vacation-limit-edit-icons {
  position: absolute;
  top: 20px;
  right: 1.5rem;
  cursor: pointer;
}

.showOnTop {
  z-index: 1;
}

.editVacationLimitInput {
  width: 2ch;
  color: rgba(0, 0, 0, 0.71);
  outline: none;
  border: none;
  line-height: 1.5;
  white-space: nowrap;
}

.donut-chart-main-text {
  font-family: 'Azeret Mono', monospace;
  font-size: 22px;
  font-weight: 500;
}

.alert-top {
  position: fixed;
  top: 80px;
  right: 20px;
  z-index: 5;
  width: auto;
}

.request-tile {
  cursor: pointer;
  transition: transform 250ms;
}

.request-tile:hover{
  transform: scale(1.02);
  border-color: rgba(118, 119, 124, 0.74) !important;
  //box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1) !important;
}

.react-datepicker__day--disabled {
  color: #e6e6e6 !important;
}

.react-datepicker__day--disabled :hover {
  color: #98a6ad;
}

.react-datepicker__day :hover {
  color: white;
}

.user-avatar-initials {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: #9aa7ae;
  color: white;
  top: 50% !important;
  transform: translate(0, -55%);
  font-weight: $font-weight-bold;
  font-size: 1rem;
}

.nav-user .account-user-avatar img {
  height: 35px;
  width: 35px;
}

body {
  overflow-y: scroll;
}

a:hover, .accordionHeaderFiles:hover {
  cursor: pointer !important;
}

.logo-tall {
  padding-top: 160px;
}

.logo-short {
  padding-top: 110px;
}

.btn-warning {
  --bs-btn-color: white;
  --bs-btn-hover-color: white
}

.custom-line {
  border: none;
  border-bottom: 2px solid rgba($gray-600, 0.2);
}

.cursor-pointer {
  cursor: pointer;
}

#enginePower::after {
  content: ' KM';
}

.object-image-container {
  width: 150px;
  height: 100px;
  padding: 0;
  object-fit: cover;
  object-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  font-size: 30px;
  background-color: #8080801f;
  color: #00000040;
  position: relative;

  .object-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 15px;
    image-rendering: auto;
  }

  .object-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: white;
    font-size: 50px;
    opacity: 0;
    transition: opacity 500ms;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
}

.custom-badge {
  font-size: 10px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

$radius: 10px;

.card {
  border-radius: $radius;
}

.card-header:first-child {
  border-radius: $radius $radius 0 0;
}

.files-accordion > .card:last-child > .card-header {
  border-radius: 0 0 $radius $radius;
  border: none;
}

.textarea {
  white-space: pre-wrap;
}

.modal-content {
  border-radius: $radius;
}

.text-muted {
  color: $text-muted !important;
}

.react-datepicker__aria-live {
  display: none;
}

.dashboard-card {
  width: 150px;
  height: 150px;
  cursor: pointer;
  transition: color 100ms ease-in-out;
  margin: 0;

  i {
    font-size: 40px;
    transition: all 250ms ease-in-out;
  }
}

.dashboard-card:hover {
  color: black;

  i {
    transform: scale(1.1);
    rotate: 5deg;
  }
}

.dashboard-table tbody tr:last-child {
  border-bottom-style: hidden;
}

.underline-hover {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: 150ms;

  &:hover {
    text-decoration-color: $dark;
  }
}

.dashboard-table-icon {
  background-color: $body-bg;
  box-shadow: $box-shadow-sm;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: $bg-leftbar;
  width: 40px;
  height: 40px;
  font-size: 25px;
}

.mb-1rem {
  margin-bottom: 1rem;
}

.line-height-normal {
  line-height: normal;
}
