// 
// tables.scss
//

//Table centered (Custom)
.table-centered {
    th, td {
        vertical-align: middle !important;
    }
}


// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
    .action-icon {
        color: $gray-600;
        font-size: 1.2rem;
        display: inline-block;
        padding: 0 3px;
    }
}

// Tables fluid
.table-nowrap {
    th, td {
      white-space: nowrap;
    }
}
